body {
  margin: 0;
  font-family: "Playfair Display" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: "Playfair Display" !important;
}

:root {
  --max-line: 8;
  --line-height: 1.4;
  --font-size: 16px;
}

.title {
  font-family: "Playfair Display";
  font-style: italic;
  font-weight: 600;
  line-height: 123.81%;
  letter-spacing: -2.52px;
  text-transform: capitalize;
  /* Primary/Main */
  color: #382e4b;
}

.sub-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 137.5%;
  color: #796e8e;
  width: 70% !important;
  letter-spacing: -0.64px;
}

.obituary {
  font-style: normal;
  height: 50px;
  border-radius: 48px !important;
  background: linear-gradient(98deg, #9455ff 43.97%, #5622e6 100%);
  color: #fff !important;
  width: 70%;
  font-family: "Poppins" !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  line-height: normal !important;
  letter-spacing: -0.36px !important;
}

.obituary-disabled {
  font-style: normal;
  height: 50px;
  border-radius: 48px !important;
  background: linear-gradient(98deg, #ae99d1 43.97%, #b2aac9 100%);
  color: #fff !important;
  width: 70%;
  font-family: "Poppins" !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  line-height: normal !important;
  letter-spacing: -0.36px !important;
  cursor:not-allowed;
}
.see-examples {
  font-family: "Lato" !important;
  text-transform: capitalize !important;
  box-sizing: border-box !important;
  color: #232227 !important;
  font-weight: 400;
  height: 49px;
  font-size: 20px !important;
  border: 1px solid #6c59b7 !important;
  border-radius: 4px !important;
}
.how-it-works {
  font-family: "Playfair Display";
  font-style: italic;
  font-weight: 500;
  font-size: 48px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.01em;
  /* Primary/Main */
  color: #6c59b7;
}

.sectionTitle {
  color: #382e4b;
  text-shadow: 3px 3px 0px #fcf8fa;
  font-family: "Playfair Display";
  font-size: 84px;
  font-style: italic;
  font-weight: 600;
  line-height: 123.81%;
  letter-spacing: -2.52px;
  text-transform: capitalize;
}

.h2 {
  font-family: "Playfair Display" !important;
  /* font-style: italic; */
  font-weight: 500;
  font-size: 24px !important;
  line-height: 120%;
  /* identical to box height, or 29px */
  text-align: center;
  letter-spacing: 0.01em;
  /* Primary/Main */
  color: #6c59b7;
}

.main-text {
  font-family: "Lato" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  line-height: 140%;
  /* or 22px */

  /* text-align: center; */

  /* Text/Main */

  color: #232227;
}

.footer-title {
  font-family: "Playfair Display";
  font-style: italic;
  font-weight: 500;
  font-size: 48px;
  line-height: 120%;
  /* identical to box height, or 58px */
  color: #fff;
  text-align: center;
  letter-spacing: 0.01em;
}

.menu-link {
  font-family: "Lato" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 20px !important;
  line-height: 140%;
  text-decoration: none;
  text-transform: uppercase;
  color: #232227 !important;
}

.menu-link-active {
  border-bottom: 3px solid #6c59b7;
  padding-bottom: 5px;
  color: #6c59b7;
}

.detailview {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #232227;
}

.footer-links {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */
  /* Text/Main */
  color: #232227;
}
.field-lablel {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */

  text-align: center;

  color: rgba(35, 34, 39, 0.6);
}
.proceed-to-preview {
  box-shadow: none !important;
  font-family: "Lato" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 20px !important;
  line-height: 140%;
  background-color: #fff !important;
  height: 50px;
  width: 300px;
  color: #232227 !important;
  text-transform: capitalize !important;
}

.outline-button {
  box-shadow: none !important;
  font-family: "Lato" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 20px !important;
  line-height: 140%;
  border-color: #fff !important;
  height: 50px;
  width: 300px;
  color: #fff !important;
  text-transform: capitalize !important;
}

.modal-button {
  color: #fff;
  background: linear-gradient(273.53deg, #9088c5 2.52%, #51438a 95.45%), #9137dd;
  font-family: Lato !important;
  font-size: 20px !important;
  line-height: 140%;
  height: 50px !important;
  box-shadow: none !important;
  text-transform: capitalize !important;
  border-radius: 50px !important;
}

.modal-button-close {
  color: #232227 !important;
  background: #fff !important;
  border: 1px solid gray !important;
  font-family: Lato !important;
  font-size: 20px !important;
  line-height: 140%;
  height: 50px !important;
  box-shadow: none !important;
  text-transform: capitalize !important;
  border-radius: 50px !important;
}

.image-previewer {
  box-shadow: 0px 0px 0px 0px #291e431a !important;
  border-radius: 10px !important;
  width: 200px !important;
  height: 180px;
}

.profile-image {
  width: 100% !important;
  height: 180px !important;
  transform: scale(1) !important;
}

.container {
  position: relative;
  width: 100% !important;
  overflow: hidden !important;
  padding-top: 56.25% !important;
}
.responsive-iframe {
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  width: 100% !important;
  height: 100% !important;
  border: none !important;
  box-shadow: 0px 0px 0px 0px #291e431a;
  border-radius: 4px !important;
}

.Mui-error {
  color: #e16f6f !important;
  border-color: #e16f6f !important;
}

:focus-visible {
  outline: none !important;
}

.truncate {
  --webkit-line-clamp: var(--max-line) !important;
  -webkit-box-orient: vertical !important;
  position: relative !important;
  overflow: hidden !important;
  display: -webkit-box !important;
  text-overflow: ellipsis !important;
  height: calc(var(--max-line) * var(--font-size) * var(--line-height));
}

.obituaries-card1 {
  --webkit-line-clamp: var(--max-line) !important;
  -webkit-box-orient: vertical !important;
  position: relative !important;
  overflow: hidden !important;
  display: -webkit-box !important;
  text-overflow: ellipsis !important;
  max-height: calc(
    var(--max-line) * var(--font-size) * var(--line-height)
  ) !important;
}

@media (max-width: 1030px) {
  .footer-title {
    font-size: 32px !important;
  }
  .menu-link {
    font-size: 14px !important;
  }
}

@media (max-width: 768px) {
  .obituary,
  .menu-link,
  .proceed-to-preview,
  .outline-button,
  .see-examples {
    font-size: 16px !important;
  }
  .modal-button,
  .modal-button-close {
    font-size: 18px !important;
    border-radius: 50px !important;
  }

  .how-it-works,
  .title {
    font-size: 32px !important;
  }
  .sub-title {
    font-size: 16px !important;
    width: 100% !important;
  }
}

@media (max-width: 600px) {
  .truncate {
    max-height: calc(
      var(--max-line) * var(--font-size) * var(--line-height)
    ) !important;
    height: unset !important;
  }
}

a:link {
  color: #6c59b7;
  background-color: transparent;
}
a:visited {
  color: #6c59b7;
  background-color: transparent;
}
a:active {
  color: #6c59b7;
  background-color: transparent;
}

.create-Obit-labels {
  font-weight: 550;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  color: #382e4b;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
